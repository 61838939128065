import React, { useState, useEffect } from "react";
import AntSider from "../../components/AntSider/AntSider";
import { Layout } from "antd";
import "react-toastify/dist/ReactToastify.css";
import ProductCards from "../../components/ChatBot/ProductCards";
import { useDispatch, useSelector } from "react-redux";
import { checkTokenExpiration } from "../../api/Token/TokenExpiration"; // Import the function
import ProgressBar from "../../components/Shared/ProgressBar";
import { setIsCollapsed } from "../../redux/slices/sidebarCollapsedSlice"; // Import action

const Home = () => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.user.accessToken);
  const menuSelected = useSelector((state) => state.menu.menuIndex);
  const [loading, setLoading] = useState(false);
  const collapsible = useSelector((state) => state.sidebarCollapsed.isCollapsed); // Use selector

  return (
    <section>
      <div
        className={`h-full w-full ${
          loading ? "flex justify-center items-center fixed inset-0 z-50" : ""
        }`}
      >
        {loading ? <ProgressBar /> : null}
      </div>
      <Layout
        className={`min-h-screen bg-mainBg overflow-hidden relative  ${
          loading ? "blur-filter" : ""
        }`}
      >
        <AntSider
          collapsibleState={collapsible}
          setCollapsible={(collapsed) => dispatch(setIsCollapsed(collapsed))} // Update collapsible state
          setLoading={setLoading}
        />
        {/* Uncomment when you need a Landing Page */}
        {menuSelected === 0 && <ProductCards />}
      </Layout>
    </section>
  );
};

export default Home;
