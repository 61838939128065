import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import { searchPlugin } from "@react-pdf-viewer/search";
import { printPlugin } from "@react-pdf-viewer/print"; // Import the print plugin

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css"; // Import search plugin styles


const PDFViewer = ({ fileUrl, onDismiss }) => {
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const highlightPluginInstance = highlightPlugin();
  const searchPluginInstance = searchPlugin();
  const printPluginInstance = printPlugin(); // Initialize the print plugin

  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageInput, GoToNextPageButton, GoToPreviousPageButton } =
    pageNavigationPluginInstance;
  const { ShowSearchPopoverButton } = searchPluginInstance;
  const { PrintButton } = printPluginInstance; // Get the Print button from the plugin

  return (
    <>
      <div className="w-[100%] flex justify-between items-center p-2 bg-gray-200">
        <div className="flex">
          <ShowSearchPopoverButton />
          <div className="flex ml-5">
            <ZoomInButton />
            <ZoomPopover />
            <ZoomOutButton />
          </div>
        </div>

        <div className="flex">
          <GoToPreviousPageButton />
          <CurrentPageInput />
          <GoToNextPageButton />
          <div className="ml-5">
            <PrintButton />
          </div>
          <div
            className="ml-5 flex justify-center items-center w-8 h-8 bg-red-500 rounded-md text-white cursor-pointer hover:bg-red-400"
            onClick={onDismiss}
          >
            <h1>X</h1>
          </div>
        </div>
      </div>
      <div className="flex w-[100%]">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer
            fileUrl={fileUrl ? fileUrl : null}
            defaultScale={1}
            plugins={[
              zoomPluginInstance,
              pageNavigationPluginInstance,
              highlightPluginInstance,
              searchPluginInstance,
              printPluginInstance, // Include the print plugin in the plugins array
            ]}
            
          />
        </Worker>
      </div>
    </>
  );
};

export default PDFViewer;
