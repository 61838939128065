import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    menuIndex: 0
  };

  export const menuSlice = createSlice({
    name: "menuSelected",
    initialState,
    reducers: {
      setMenuIndex: (state, action) => {
        state.menuIndex = action.payload;
      }
    },
  });
  
  
  export const { 
    setMenuIndex 
  } = menuSlice.actions;
  
  export default menuSlice.reducer;