import { createSlice } from "@reduxjs/toolkit";
import io from "socket.io-client";

export const socketIoSlice = createSlice({
  name: "socketIo",
  initialState: {
    socketIo: null,
    isConnected: false,
    room_id: "",
  },

  reducers: {
    setSocket(state, action) {
      state.socketIo = action.payload;
    },
    setConnected(state, action) {
      state.isConnected = action.payload;
    },
    clearSocket(state) {
      state.socketIo = null;
      state.isConnected = false;
    },
    setRoomId(state, action) {
      state.room_id = action.payload;
    },
    clearRoomId(state) {
      state.room_id = "";
    },
  },
});

export const { setSocket, setConnected, clearSocket, setRoomId, clearRoomId } =
  socketIoSlice.actions;
export const selectSocketIo = (state) => state.socketIo.socketIo;
export const selectRoomId = (state) => state.socketIo.room_id;

export const initializeSocket = () => (dispatch) => {
  const socket = io(`${process.env.REACT_APP_BACKEND_URL}/chat`, {
    transports: ["websocket"],
    reconnection: true, // Enables reconnection
    reconnectionAttempts: Infinity, // Infinite reconnection attempts
    reconnectionDelay: 1000, // Initial delay of 1 second
    reconnectionDelayMax: 5000,
    pingTimeout: 300000, // Ping timeout increased to 30 seconds
    pingInterval: 250000, // Ping interval increased to 25 seconds
  });
  dispatch(setSocket(socket));

  socket.on("connect", () => {
    dispatch(setConnected(true));
    console.log("Socket connected.");
  });

  socket.on("room_assigned", (data) => {
    dispatch(setRoomId(data.room_id));
    console.log(`Assigned room ID: ${data.room_id}`);
  });

  /*socket.on("disconnect", () => {
    dispatch(setConnected(false));
    console.log("Socket disconnected.");
 
  });*/
  socket.on("disconnect", (reason, details) => {
    // the reason of the disconnection, for example "transport error"
    console.log(reason);

    dispatch(setConnected(false));
    console.log("Socket disconnected.");
  });
};
export const disconnectSocket = () => (dispatch, getState) => {
  const { socketIo, isConnected } = getState().socketIo;
  if (socketIo && isConnected) {
    try {
      socketIo.emit("end_session");

      socketIo.once("session_ended", () => {
        socketIo.disconnect();
        dispatch(clearSocket());
        dispatch(clearRoomId());
        console.log("Socket disconnected after ending session.");
      });
    } catch (error) {
      console.error("Error during socket shutdown:", error);
    }
  } else {
    console.log("Socket is already disconnected or not initialized.");
  }
};

export default socketIoSlice.reducer;
