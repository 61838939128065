import React, { useState, useEffect } from "react";
import { Layout, Menu, Avatar } from "antd";
import "./AntSider.css";
import { ChatBot } from "../ChatBot/ChatBot";
import Settings from "../Settings/Settings";

// For Dark Theme
// import generateGray from "../../assets/images/generateGray.png";
// import generateFilled from "../../assets/images/generateFilled.png";
// import settingsFilled from "../../assets/images/settingsFilled.png";
// import settingsGray from "../../assets/images/settingsGray.png";
// import homeLogo from "../../assets/images/homeLogo.png";
// import homeFilled from "../../assets/images/homeFilled.png";

// For Light Theme
import generateGray from "../../assets/images/chatBlack.png";
import generateFilled from "../../assets/images/chatBlack.png";
import settingsFilled from "../../assets/images/settingsBlack.png";
import settingsGray from "../../assets/images/settingsBlack.png";
import homeLogo from "../../assets/images/homeBlack.png";
import homeFilled from "../../assets/images/homeBlack.png";

import collapsibleIcon from "../../assets/icons/collapse-icon.svg";
import { LogoutOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  setProductSelected,
  setProductType,
} from "../../redux/slices/chatbotStatesSlice";
import { setMenuIndex } from "../../redux/slices/menuSlice";
import { setProductApi } from "../../api/SettingsApi/ProductApi";
import { Logout } from "../../authentication/AuthenticationAPI/AuthenticationAPI";
const { Sider } = Layout;
const { SubMenu } = Menu;

const AntSider = ({ collapsibleState, setCollapsible, setLoading }) => {
  const dispatch = useDispatch();

  const userName = useSelector((state) => state.user.user.name);
  const productType = useSelector((state) => state.chatbotStates.productType);
  const avatarName = userName ? userName[0] : "";
  const accessToken = useSelector((state) => state.user.user.accessToken);
  const menuIndex = useSelector((state) => state.menu.menuIndex);

  const [color, setColor] = useState("");
  const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

  // Icon Initialization

  // const iconsGrey = [homeLogo, generateGray, settingsGray];
  // const iconsFilled = [homeFilled, generateFilled, settingsFilled];

  // // Initializing Menu Labels
  // const menuLabels = ["Home", "Review", "Settings"];
  const iconsGrey = [homeLogo, generateGray];
  const iconsFilled = [homeFilled, generateFilled];

  // Initializing Menu Labels
  const menuLabels = ["Home", "Review"];

  const updateRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * ColorList.length);
    setColor(ColorList[randomIndex]);
  };

  useEffect(() => {
    updateRandomColor();
  }, [userName]);

  function handleLogout() {
    Logout(accessToken, dispatch);
  }

  return (
    <>
      <Sider
        className="sider-wrapper z-200"
        width={200}
        breakpoint="lg"
        onBreakpoint={(broken) => {
          setCollapsible(broken);
        }}
        onCollapse={(collapsed, type) => {
          setCollapsible(collapsed);
        }}
        collapsed={collapsibleState}
        collapsible
        trigger={
          <div
            className={`${
              collapsibleState ? "justify-center" : "justify-end pe-4"
            } bg-transparent flex items-center hover:brightness-50 transition-all duration-300`}
          >
            <img
              src={collapsibleIcon}
              style={{ height: "12px", width: "12px" }}
              alt="collapsible"
              className={`w-[11px] transition-all duration-300 ${
                collapsibleState ? "scale-x-[-1]" : ""
              }`}
            />
          </div>
        }
      >
        <div className="sider-content">
          {!collapsibleState ? (
            <div className="w-full flex text-white items-center h-[5.3rem] pt-2 pl-7">
              <span className="font-poppins text-[16px] font-bold text-textColor">
              ContractVerify
              </span>
            </div>
          ) : (
            <div className="collapse-icon w-full flex items-center h-[5.3rem] bg-mainBg rounded-t-xl"></div>
          )}
          <Menu
            className="bg-transparent pl-0 text-textSiderDull text-sm  font-poppins"
            mode="inline"
            defaultSelectedKeys={["1"]}
            selectedKeys={[`item-${menuIndex}`]}
          >
            {menuLabels.map((label, index) => (
              <Menu.Item
                key={`item-${index}`}
                icon={
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={
                      menuIndex === index
                        ? iconsFilled[index]
                        : iconsGrey[index]
                    }
                    alt={label}
                  />
                }
                onClick={() => {
                  dispatch(setMenuIndex(index));
                  console.log(menuIndex);
                }}
              >
                {label}
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className="sider-footer">
          <div className="sider-bottom">
            {/* <div
              className={`flex items-center gap-2 cursor-pointer 
              ${collapsibleState ? "pl-[25%]" : "pl-7"}`}
            >
              <Avatar
                style={{
                  backgroundColor: color,
                  verticalAlign: "middle",
                }}
                size={33}
              >
               M
              </Avatar>
              {!collapsibleState && (
                <p className="font-poppins text-textColor text-sm">
                  Max Payne
                </p>
              )}
            </div> */}
            {/* <div className="flex justify-center mb-2 mt-4">
              <Button
                icon={<LogoutOutlined />}
                onClick={handleLogout}
                className="text-white"
              >
                {!collapsibleState ? "Logout" : ""}
              </Button>
            </div> */}
          </div>
        </div>
      </Sider>

      {menuIndex === 1 && <ChatBot setLoading={setLoading} />}
      {/* {menuIndex === 2 && <Settings />} */}
    </>
  );
};

export default AntSider;
