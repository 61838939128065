import React from "react";
import { Card, Col, Row, Layout } from "antd";
import { ArrowRightOutlined, WechatOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setMenuIndex } from "../../redux/slices/menuSlice";
import { setComponentMounted } from "../../redux/slices/chatbotStatesSlice";

export default function ProductCards() {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.user.accessToken);
  const user_email = useSelector((state) => state.user.user.email);

  const cardStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.10)", // White color with 20% opacity
    color: "#FFFFFF", // White text color
    borderRadius: "8px", // Rounded corners
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.1)", // Shadow for glossy effect
    cursor: "pointer", // Cursor set to pointer
  };

  return (
    <>
      <Layout className="relative z-10 ">
        <div className="flex justify-center items-center min-h-screen">
          <div className="bg-overlayBg h-full w-full ">
            <div className="flex flex-col justify-center items-center px-4 mt-[11%] 2xl:mt-[14%]">
              <WechatOutlined
                className="text-textColor"
                style={{ fontSize: "66px" }}
              />{" "}
              <h1 className="text-textColor font-poppins font-medium text-lg lg:text-xl 2xl:text-2xl mt-2 mb-14 2xl:mb-20">
                How can I help you today?
              </h1>
              <Row gutter={[24, 24]} justify="center">
                <Col xs={{ span: 24 }} md={{ span: 10 }}>
                  <Card
                    bordered={false}
                    style={cardStyle}
                    onClick={() => {
                      dispatch(setMenuIndex(1));
                      dispatch(setComponentMounted({ componentMounted: "Uploader" }));
                    }}
                  >
                    <div className="flex justify-between items-center  ">
                      <span className="font-poppins text-textColor font-medium  text-base 2xl:text-lg">
                        Rule Verification
                      </span>
                      <ArrowRightOutlined />
                    </div>
                    <p className="font-poppins text-textColor text-sm font-normal mt-5">
                     Discover our AI-powered Rule Verification System: Upload rules, verify their presence in contracts, and identify discrepancies effortlessly.
                     Enhance accuracy with in-platform editing and receive a concise report summarizing the findings
                      <br />
                      <br />
                      Simplify truth-finding with technology! 🚀💼
                    </p>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
