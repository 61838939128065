import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    componentMounted: "Uploader",
  };

  export const chatbotStatesSlice = createSlice({
    name: "chatbotStates",
    initialState,
    reducers: {
      setComponentMounted: (state, action) => {
        state.componentMounted = action.payload.componentMounted ;
      },
      clearComponentMounted: (state) => {
        state.componentMounted = initialState.componentMounted;
      },
     
    },
  });
  
  
  export const { 
    setComponentMounted, 
    clearComponentMounted,
  } = chatbotStatesSlice.actions;
  
  export default chatbotStatesSlice.reducer;