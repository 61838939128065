import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Authentication from "./authentication/Authentication";
import { useSelector } from "react-redux";

function App() {

  const user = useSelector((state) => state.user.user)

  return (
    <div className="App">
      {/* Uncomment when you need Authentication */}
      {/* <Routes>
        {user.accessToken ? 
        <Route path="/" element={<Home />}></Route>
        : 
        <Route path="/" element={<Authentication />}></Route>
         }
      </Routes> */}

      <Routes>
        <Route path="/" element={<Home />}></Route>
      </Routes>

    </div>
  );
}

export default App;
