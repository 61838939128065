import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isCollapsed: false
  };

  export const sidebarCollapsedSlice = createSlice({
    name: "sidebarCollapsed",
    initialState,
    reducers: {
      setIsCollapsed: (state, action) => {
        state.isCollapsed = action.payload;
      }
    },
  });
  
  
  export const { 
    setIsCollapsed 
  } = sidebarCollapsedSlice.actions;
  
  export default sidebarCollapsedSlice.reducer;