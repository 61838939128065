import React, { useState, useEffect } from "react";
import { Table } from "antd";
import PDFViewer from "../PDFViewer/PDFViewer"; // Ensure this is the correct path to your PDFViewer component
import { useDispatch, useSelector } from "react-redux";
import { setIsCollapsed } from "../../redux/slices/sidebarCollapsedSlice";

const ClaimChecker = ({ chatWindowRef, responseData }) => {
  const [selectedSource, setSelectedSource] = useState(null);
  const collapsible = useSelector((state) => state.sidebarCollapsed.isCollapsed); // Use selector

  const dispatch = useDispatch();
  useEffect(() => {
    if (chatWindowRef && chatWindowRef.current) {
      chatWindowRef.current.scrollTop = 0;
    }
  }, [responseData, chatWindowRef]);

  const columns = [
    {
      title: "Rule No",
      dataIndex: "ruleno",
      key: "ruleno",
    },
    {
      title: "Rule",
      dataIndex: "rule",
      key: "rule",
    },
    {
      title: "Relevant Excerpt",
      dataIndex: "relevant_excerpt",
      key: "relevant_excerpt",
    },
    {
      title: "Section No",
      dataIndex: "section_number",
      key: "section_number",
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
    },
    {
      title: "Verification Status",
      dataIndex: "verification_status",
      key: "verification_status",
      render: (text) => {
        return (
          <span
            style={{
              color:
                text === "Discrepancy Found"
                  ? "red"
                  : text === "Review Needed"
                  ? "navy"
                  : "inherit",
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (text) => {
        if (!text) {
          return <span className="text-center">No Source</span>;
        }

        const handleClick = () => {
          dispatch(setIsCollapsed(true))
          const byteCharacters = atob(text);
          const byteNumbers = new Array(byteCharacters.length)
            .fill()
            .map((_, i) => byteCharacters.charCodeAt(i));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setSelectedSource(url);
        };

        return (
          <button
            onClick={handleClick}
            className="hover:text-blue-500 underline"
          >
            View Source
          </button>
        );
      },
    },
  ];

  // If responseData is empty or not an array, provide default values for data
  let data = [];
  if (
    Array.isArray(responseData.verifications) &&
    responseData.verifications.length > 0
  ) {
    data = responseData.verifications.map((item, index) => ({
      ...item,
      key: index,
      ruleno: index + 1, // Adding index here
    }));
  } else {
    // Provide default values for data when responseData is empty or not an array
    data = [
      {
        key: 0,
        ruleno: 1,
        rule: "",
        relevant_excerpt: "",
        section_number: "",
        review: "",
        verification_status: "",
      },
    ];
  }

  return (
    <>
      {selectedSource && (
        <div className={collapsible?  "fixed z-50 w-[85%] h-screen overflow-y-auto":"fixed z-50 w-[80%] h-screen overflow-y-auto"}>
          <PDFViewer
            fileUrl={selectedSource}
            onDismiss={() => {
              setSelectedSource(null);
              dispatch(setIsCollapsed(false))
            }}
          />
        </div>
      )}
      <Table columns={columns} dataSource={data} />
    </>
  );
};

export default ClaimChecker;
