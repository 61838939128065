import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";


const ProgressBar = () => {

    const progress = useSelector(
      (state) => state.loader.progress
    );
    const progressMessage = useSelector(
      (state) => state.loader.progressMessage
    );


  return (
    <div className="progress-bar-container flex flex-col items-center">
      <p className="mb-2">{progressMessage}</p>
      <div className="progress-bar">
        <div
          className="progress-fill"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
