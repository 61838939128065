import React, { useEffect, useState } from "react";
import "./Uploader.css";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import FilledButton from "../Common/FilledButton/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { setComponentMounted } from "../../redux/slices/chatbotStatesSlice";
import {
  selectSocketIo,
  initializeSocket,
  disconnectSocket,
} from "../../redux/slices/socketioSlice";
import {
  setProgress,
  setProgressMessage,
} from "../../redux/slices/loaderSlice";
import FileCard from "./FileCard";

const { Dragger } = Upload;

const fileTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.ms-excel", // .xls
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "application/msword", // .doc
  "application/pdf", // .pdf
];

const Uploader = ({ setData, setLoading, data }) => {
  const [claims, setClaims] = useState([]);
  const [references, setReferences] = useState([]);
  const [referenceFilenames, setReferenceFilenames] = useState([]);
  const dispatch = useDispatch();
  const socket = useSelector(selectSocketIo);

  const handleDelete = (fileToDelete, type) => {
    const setter = type === "claims" ? setClaims : setReferences;
    setter((prevFiles) =>
      prevFiles.filter((file) => file.uid !== fileToDelete.uid)
    );
    if (type === "references") {
      setReferenceFilenames((prev) =>
        prev.filter((name) => name !== fileToDelete.name)
      );
    }
    message.success(`${fileToDelete.name} has been deleted.`);
  };

  const commonProps = (type, setState) => ({
    name: "file",
    multiple: true,
    showUploadList: false,
    beforeUpload: (file) => {
      if (!fileTypes.includes(file.type)) {
        message.error(`${file.name} is not a supported file type.`);
        return Upload.LIST_IGNORE;
      }
      Object.assign(file, { fileType: type });
      return true;
    },
    onChange(info) {
      const { file } = info;
      if (file.status === "done") {
        message.success(`${file.name} file uploaded successfully.`);
        setState((prevFiles) => {
          const existingFile = prevFiles.find((f) => f.uid === file.uid);
          if (!existingFile) {
            return [...prevFiles, file];
          }
          return prevFiles;
        });
        if (type === "references") {
          setReferenceFilenames((prev) => [...prev, file.name]);
        }
      } else if (file.status === "error") {
        message.error(`${file.name} file upload failed.`);
      } else if (file.status === "removed") {
        setState((prevFiles) => prevFiles.filter((f) => f.uid !== file.uid));
        if (type === "references") {
          setReferenceFilenames((prev) =>
            prev.filter((name) => name !== file.name)
          );
        }
      }
    },
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok", { ...file, status: "done" });
      }, 500);
    },
  });

  useEffect(() => {
    dispatch(initializeSocket());
    return () => {
      dispatch(disconnectSocket());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!socket) {
      console.log("Socket connection not established.");
      return;
    }

    const handleUploadResponse = (data) => {
      console.log("handleUploadResponse data:", data);
      if (data.isFinal) {
        if (
          typeof data.data === "string" &&
          (data.data.includes("Error") ||
            data.data.includes("No reference") ||
            data.data.includes("No claim"))
        ) {
          message.error(data.data);
          setLoading(false);
          dispatch(setProgress({ progress: 0 }));
        } else {
          const payload = {
            verificationData: data.data,
            referenceFilenames,
          };
          setData(payload);
          setLoading(false);
          dispatch(setProgress({ progress: 100 }));
          dispatch(
            setProgressMessage({ progressMessage: "Verification Complete" })
          );
          console.log("success");
          dispatch(setComponentMounted({ componentMounted: "ChatBot" }));
        }
      } else {
        console.log("Step received:", data.step);
        switch (data.step) {
          case 0:
            setTimeout(() => {
              dispatch(setProgress({ progress: 90 }));
              dispatch(setProgressMessage({ progressMessage: data.data }));
            }, 1000);
            break;
          case 1:
            setTimeout(() => {
              dispatch(setProgress({ progress: 30 }));
              dispatch(setProgressMessage({ progressMessage: data.data }));
            }, 5000);
            break;
          case 2:
            setTimeout(() => {
              dispatch(setProgress({ progress: 50 }));
              dispatch(setProgressMessage({ progressMessage: data.data }));
            }, 3000);
            break;
          case 3:
            setTimeout(() => {
              dispatch(setProgress({ progress: 70 }));
              dispatch(setProgressMessage({ progressMessage: data.data }));
            }, 5000);
            break;
          default:
            console.error("Unexpected step in upload response");
            message.error(
              "An unexpected error occurred during file processing."
            );
            setLoading(false);
            break;
        }
      }
    };

    socket.on("upload_response", handleUploadResponse);

    return () => {
      socket.off("upload_response", handleUploadResponse);
    };
  }, [socket, data, dispatch, setData, setLoading, referenceFilenames]);

  const handleUpload = () => {
    try {
      setLoading(true);
      dispatch(setProgress({ progress: 10 }));
      dispatch(
        setProgressMessage({ progressMessage: "Files are being uploaded..." })
      );

      const claimsData = claims.map((file) => ({
        filename: file.name,
        content: file.originFileObj,
      }));

      const referencesData = references.map((file) => ({
        filename: file.name,
        content: file.originFileObj,
      }));
      console.log("Uploading data", {
        claimsData,
        referencesData,
      });

      if (socket && socket.emit) {
        console.log("Emitting data through socket");
        socket.emit("upload", {
          files: { claims: claimsData, references: referencesData },
        });
      } else {
        throw new Error(
          "Socket is not connected or emit function is not available."
        );
      }
      console.log("done uploading");
    } catch (error) {
      console.error("Error during file upload:", error);
      dispatch(
        setProgressMessage({
          progressMessage: "File upload failed. Please try again.",
        })
      );

      setLoading(false);
    }
  };
  
  return (
    <div className="flex w-[100%] justify-center m-auto items-center">
      <div>
        <span className="flex justify-center mb-8">
          <p className="font-poppins text-textColor text-lg font-medium">
            Upload your Contract & Rules Here
          </p>
        </span>
        <div className="flex flex-col justify-center items-center">
          {/* Dragger for references */}
          <div className="flex flex-col h-fit">
            <Dragger
              {...commonProps("references", setReferences)}
              className={
                references.length > 0 || claims.length > 0
                  ? "w-[500px]"
                  : "w-[600px]"
              }
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: "#000" }} />
              </p>
              <p className="text-textColor font-medium font-poppins">
                Please start by uploading your contract document here
              </p>
              <p className="text-uploaderSubText font-poppins">
                Click or drag file to this area to upload
              </p>
            </Dragger>
            {/* {references.map((file) => (
              <FileCard
                key={file.uid}
                fileName={file.name}
                onDelete={() => handleDelete(file, "references")}
              />
            ))} */}
          </div>
          {/* Dragger for claims */}
          <div className={`flex flex-col h-fit mt-10 `}>
            <Dragger
              {...commonProps("claims", setClaims)}
              className={
                references.length > 0 || claims.length > 0
                  ? "w-[500px]"
                  : "w-[600px]"
              }
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ color: "#000" }} />
              </p>
              <p className="text-textColor font-medium font-poppins">
                Please start by uploading your rules documents here
              </p>
              <p className="text-uploaderSubText font-poppins">
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </div>
          <div className="mt-10 w-[100%] flex justify-center items-center">
            <FilledButton handleUpload={handleUpload} />
          </div>
        </div>
      </div>

      <div
        className={
          references.length > 0 || claims.length > 0
            ? "flex flex-col w-[30%] h-screen gap-8 ml-[8%]"
            : "hidden"
        }
      >
        {references.length > 0 && (
          <div className="w-[100%] h-[40%] rounded-md flex bg-siderBg justify-start flex-col items-center pt-7 pb-7 gap-2 overflow-y-auto shadow">
            <h1 className="font-poppins mb-3 font-semibold">
              Contract Uploaded
            </h1>
            {references.map((file) => (
              <FileCard
                key={file.uid}
                fileName={file.name}
                onDelete={() => handleDelete(file, "references")}
              />
            ))}
          </div>
        )}

        {claims.length > 0 && (
          <div className="w-[100%] h-[40%] rounded-md flex bg-siderBg justify-start flex-col items-center pt-7 pb-7 gap-2 overflow-y-auto shadow">
            <h1 className="font-poppins mb-3 font-semibold">Rules Uploaded</h1>

            {claims.map((file) => (
              <FileCard
                key={file.uid}
                fileName={file.name}
                onDelete={() => handleDelete(file, "claims")}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Uploader;
