import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageLoader: false,
  progress: 0,
  progressMessage: 'Starting Process...'
};

export const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setPageLoader: (state, action) => {
      state.pageLoader = action.payload.pageLoader;
    },
    clearPageLoader: (state) => {
      state.pageLoader = initialState.pageLoader;
    },
    setProgress: (state, action) => {
      state.progress = action.payload.progress;
    },
    clearProgress: (state) => {
      state.progress = initialState.progress;
    },
    setProgressMessage: (state, action) => {
      state.progressMessage = action.payload.progressMessage;
    },
    clearProgressMessage: (state) => {
      state.progressMessage = initialState.progressMessage;
    },
    
  },
});

// Action creators are generated for each case reducer function
export const {
  setPageLoader,
  clearPageLoader,
  setProgress,
  clearProgress,
  setProgressMessage,
  clearProgressMessage
} = loaderSlice.actions;

export default loaderSlice.reducer;
