// Imports
import { useRef, useState } from "react";
import { Layout, message } from "antd";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./ChatBot.css";
import Header from "../Header/Header";
import Messages from "../Messages/Messages";
import InputSection from "../Input/InputSection";
import {
  sendMessageApi,
  submitFeedbackApi,
  uploadFileApi,
} from "../../api/ChatBotApi/ChatBotApi";
import Uploader from "../Uploader/Uploader";
import ClaimChecker from "./ClaimChecker";
import { ThreeCircles } from "react-loader-spinner";
import ProgressBar from "../Shared/ProgressBar";
// Imports End

export const ChatBot = ({setLoading}) => {
  // Initialize States
  const chatWindowRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const componentMounted = useSelector(
    (state) => state.chatbotStates.componentMounted
  );
  const accessToken = useSelector((state) => state.user.user.accessToken);
  const [data, setData] = useState([]);
  const [referenceFilenames, setReferenceFilenames] = useState([]);
  // Initialize End
  

  // Handles SendMessage
  const sendMessage = async (msg) => {
    setLoading(true);
    setMessages([
      ...messages,
      {
        msg: msg,
        sender: "User",
        type: "text",
      },
    ]);

    try {
      const res = await sendMessageApi(msg, accessToken);
      setMessages((prevMessages) => [
        ...prevMessages,
        { msg: res.result, sender: "bot", type: "text", source: res.source },
      ]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // Handles Bulk Upload
  const bulkUpload = async (file) => {
    setLoading(true);
    try {
      const res = await uploadFileApi(file, accessToken);
      console.log(res);
      message.success(res.message);
    } catch (error) {
      console.error(error);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSetData = (payload) => {
    setData(payload.verificationData);
    setReferenceFilenames(payload.referenceFilenames);
  };

  return (
    <Layout className="min-h-screen max-h-[20px] relative z-10">
      <>
        <Layout
          className={`flex flex-col h-full w-full bg-overlayBg ${
            componentMounted !== "Uploader"
              ? "items-stretch"
              : ""
          }`}
        >
          <Header
        referenceFilenames={referenceFilenames}
        />
          {componentMounted == "Uploader" ? (
            <Uploader setData={handleSetData} setLoading={setLoading} data={data}/>
          ) : (
            <>
              <div
                ref={chatWindowRef}
                className="min-h-[80vh] max-h-[20px] overflow-y-scroll w-[90%] mt-5 mx-auto"
              >
                <ClaimChecker
                  chatWindowRef={chatWindowRef}
                  responseData={data}
                />
                {/* {messages.length > 0 && (
                  <Messages
                    messages={messages}
                    setMessages={setMessages}
                    chatWindowRef={chatWindowRef}
                    // loading={loading}
                  />
                )} */}
              </div>
              {/* <InputSection sendMessage={sendMessage} bulkUpload={bulkUpload} /> */}
            </>
          )}
        </Layout>
      </>
    </Layout>
  );
};