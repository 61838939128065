import React from 'react';
import "./FilledButton.css";
import { Button } from 'antd';

const FilledButton = ({handleUpload}) => {
  return (
    <Button className="next-btn text-uploadBtnText bg-uploaderBtn border-0 tracking-wide font-poppins font-medium w-2/12 h-10" onClick={handleUpload}>Next</Button>
  )
}

export default FilledButton