import React from "react";
import "./Header.css";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import expandIcon from "../../assets/icons/expandIcon.png";
import { Dropdown } from "antd";

const Header = ({referenceFilenames}) => {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.user.user.accessToken);
  const componentMounted = useSelector((state) => state.chatbotStates.componentMounted);

  let headerTitle =
    componentMounted === "Uploader"
      ? "Upload Contract  & Rules"
      : `Rule Verifier ${referenceFilenames.length > 0 ? ` - ${referenceFilenames.join(", ")}` : ""}`;

  

  return (
    <div className="flex mt-8 pl-6 text-textColor font-poppins">
      <div
        className={`header-left-wrapper flex items-center gap-2 lg:gap-3 xl:gap-4 transition-all duration-300`}
      >
        <span className="header-name flex gap-2 font-medium text-xs sm:text-base md:text-lg xl:text-lg">
          <span>{headerTitle}</span>
          {/* 
          <span>|</span>
          <span className="flex items-center gap-1">
            <span className="text-headerProduct">{productType}</span>
            <span>
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <img className="cursor-pointer" style={{ width: 20, height: 20 }} src={expandIcon} />
              </Dropdown>
            </span>
          </span>
                */}
        </span>
        {/* 
        <span className="header-visibility">
          <button className="visibility-btn bg-lightBlue text-white" onClick={handleUpdate}>
            
            Update
          </button> 
        </span> */}
      </div>

    </div>
  );
};

export default Header;
