import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import userReducer from "./slices/userSlice";
import loaderReducer from "./slices/loaderSlice";
import chatbotStatesReducer from "./slices/chatbotStatesSlice";
import sidebarCollapsedSlice from "./slices/sidebarCollapsedSlice";
import menuReducer from "./slices/menuSlice";
import socketIoReducer from "./slices/socketioSlice";

const persistConfig = {
  key: "root",
  storage,
  //blacklist: [],
};

const rootReducer = combineReducers({
  user: userReducer,

  loader: loaderReducer,
  chatbotStates: chatbotStatesReducer,
  menu: menuReducer,
  socketIo: socketIoReducer,
  sidebarCollapsed:sidebarCollapsedSlice
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default configureStore({
  // reducer: rootReducer,
  reducer: persistedReducer,
});
