import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: {
        email: "",
        name: "",
        phoneNumber: "",
        isVerified: false,
        accessToken: "",
        role: {
          isAdmin: false,
          isEmployee: false,
          isManager: false
        }
    }
  };

  export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      setUser: (state, action) => {
        // Update only the specified properties, others remain unchanged
        state.user = {
          ...state.user,
          ...action.payload.user,
        };
      },
      clearUser: (state) => {
        return initialState; // Reset the user state to its initial state
      },
    },
  });
  
  // Action creators are generated for each case reducer function
  export const { setUser, clearUser } =
    userSlice.actions;
  
  export default userSlice.reducer;